/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */

import moment from "moment";
import { isTemplateExpression } from "typescript";
import {
  iSelectDataProps,
  IDisputes,
  iFormDefaultData,
  iSelectProps,
  IApplicationSectionA,
} from "./interfaces";
import { Account } from "../types/Account";
/* eslint-disable no-restricted-syntax */
export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isThereEmptyFields = (state: iFormDefaultData): boolean => {
  let response = false;
  const stateArrayValues = Object.values(state);
  for (let i = 0; i < stateArrayValues.length; i += 1) {
    if (stateArrayValues[i].error) {
      response = false;
      break;
    } else {
      response = true;
    }
  }
  return response;
};

export const checkForEmptyFields = (
  stateData: iFormDefaultData,
  stateHook: React.Dispatch<React.SetStateAction<iFormDefaultData>>
): boolean => {
  for (const x in stateData) {
    const values = stateData[x];
    if (values?.data?.trim) {
      if (values.data.trim() === "") {
        try {
          stateData[x] = { data: stateData[x].data, error: true };
        } catch (e) {
          // do nothing
        }
        stateHook({ ...stateData });
      }
    }
  }

  return isThereEmptyFields(stateData);
};

export const formatFileSize = (size: number): string => {
  const i: number = Math.floor(Math.log(size) / Math.log(1024));
  const pw: number = Math.pow(1024, i);
  const s: number = size / pw;

  return `${(s * 1).toFixed(2)} ${["B", "kB", "MB", "GB", "TB"][i]}`;
};
export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatDisputeData = (data: any[]): IDisputes[] =>
  data.map((_data) => {
    const cDate = moment(_data?.creation_date).format("Do MMM, YYYY");
    const claim = _data?.case_details?.claim;
    const main_case_file_number = _data?.case_details?.main_case_file_number;
    const file_number_data = _data?.case_details?.mediation_file_number;
    const case_status = _data?.case_details?.case_status;
    return {
      reference_id: Number(_data?.adr_id),
      status: case_status,
      progress: `${Math.floor(Math.random() * 100) + 1}%`,
      claim,
      main_file_number: main_case_file_number,
      file_number: file_number_data,
      case_source: _data?.case_details?.case_source,
      date_filled: cDate,
      adr_type: _data.adr_type,
    };
  });
export const calculateAge = (dateString: string, withMonth = false): any => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return withMonth ? `${age} years, ${Math.abs(m)} months` : age;
};

export const formatConsolidatedFormData = (data: Array<any>) => {
  return data.map((item) => ({
    name: item?.name,
    data: {
      service_id: item?.id,
      no_of_session: 0,
      female_9: 0,
      male_9: 0,
      female_14: 0,
      male_14: 0,
      female_others: 0,
      male_others: 0,
      female_total: 0,
      male_total: 0,
      comment: 0,
    },
  }));
};

export const validatePhoneNumber = (tel: string): boolean => {
  if (tel === "0") return true; // this is an exception to go with for now
  const reg = new RegExp("^[0-9]{10}$");

  return reg.test(tel);
};

export const truncateString = (str: string, count: number) =>
  str.length > count
    ? `${str.replace(/<\/?[^>]+(>|$)/g, "").slice(0, count)}...`
    : str.replace(/<\/?[^>]+(>|$)/g, "");

export const formatDate = (date: string) =>
  date && moment(date).format("Do MMM, YYYY");

export const getPathNames = (state: any, id: string) =>
  Object.keys(state)
    .filter((path) => path.includes(id))
    .map((path) => state[path].data);

export const formatErrorMessage = (data: any) => {
  const response = [];
  const keys = Object.keys(data);
  for (const x of keys) {
    response.push(`${x} - ${data[x][0]}`);
  }
  return response.join(" ");
};

export const formatMultipleSelectData = (data: any[]) =>
  data.map((res) => ({ value: res?.name, label: res?.name }));
export const formatSelectData = (data: any[]) =>
  data.map((res) => ({ value: res?.id, label: res?.name }));

export const formatSelectDataName = (data: any[]) =>
  data.map((res) => ({ value: res?.project_name, label: res?.name }));

export const formatBidStageSelectData = (data: any[]) =>
  data.map((res) => ({ value: res?.order_no, label: res?.order_no + 1 }));

export const formatProductSelectData = (data: any[]) =>
  data.map((res) => ({ value: res?.id, label: res?.name }));

export const formatDepartmentSelectData = (data: any[]) =>
  data.map((res) => ({ value: res?.id, label: res?.name }));

export const formatAcountSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.account_code} ${res?.account_name}`,
  }));

export const formatAccountSubCategorySelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.sub_cat_code} ${res?.sub_cat_name}`,
  }));
export const formatUserSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.first_name} ${res?.last_name}`,
  }));

export const formatSelectedRequisitionData = (data: any[]) =>
  data.map((res) => ({
    value: res?.item.id,
    label: res?.item.name,
  }));

export const formatCustomerSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: res?.name,
  }));

export const formatStaffSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.user?.first_name} ${res?.user?.last_name}`,
  }));
export const formatSupplierSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.name}`,
  }));

  export const formatBidsSelectData = (data: any[]) =>
    data.map((res) => ({
      value: res?.id,
      label: `${res?.description}`,
    }));

export const formatPatientData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: `${res?.first_name} ${res?.last_name}`,
  }));

export const getInitials = (str: string | undefined) => {
  if (str) {
    const splited = str.split(" ");
    return `${splited[0][0].toLocaleUpperCase()}${splited[1][0].toLocaleUpperCase()}`;
  }
  return str;
};

export const formatDateToHumanReadable = (data: string, split = false) => {
  const response = moment(data).format("MMM Do YY, h:mm:ss a");
  if (split) {
    return response.split(",");
  }
  return response;
};

export const padString = (str: string) => {
  if (!str) return str;
  const xx = str.slice(0, 50);
  const x = xx.padEnd(xx.length, "...");
  return x.replace(/<\/?[^>]+(>|$)/g, "");
};

export const addDays = (startDate: Date, days: number) => {
  return moment(startDate).add(days, "days").format("YYYY-MM-DD");
};

export const formatStatus = (status: number) => {
  let className;
  let title;
  switch (status) {
    case 2:
      className = "badge-success";
      title = "Success";
      break;
    case 3:
      className = "badge-danger";
      title = "Declined";
      break;
    case 4:
      className = "badge-primary";
      title = "On going";
      break;
    default:
      className = "badge-info";
      title = "Pending";
      break;
  }

  return { title, className };
};

export const formatBillStatus = (status: number) => {
  let className;
  let title;
  let icon;
  switch (status) {
    case 2:
      className = "badge-success";
      title = "Paid out";
      icon = "fa-check-circle";
      break;
    case 3:
      className = "badge-danger";
      title = "Declined";
      icon = " fa-ban";
      break;
    default:
      className = "badge-info";
      title = "Pending";
      icon = "fa-clock-o";
      break;
  }

  return { title, className, icon };
};

export const sortById = (rowA: any, rowB: any) => {
  const a = rowA.id;
  const b = rowB.id;

  return b - a;
};

export const formatState = (status: string) => {
  let className;
  let title;
  switch (status) {
    case "Reviewed":
      className = "badge-info";
      title = "Reviewed";
      break;
    case "Approved":
      className = "badge-success";
      title = "Approved";
      break;
    case "Declined":
      className = "badge-danger";
      title = "Declined";
      break;
    case "Completed":
      className = "badge-default";
      title = "Completed";
      break;
    default:
      className = "badge-secondary";
      title = "Pending";
      break;
  }

  return { title, className };
};

export const formatStatusID = (status: number) => {
  let className;
  let title;
  switch (status) {
    case 2:
      className = "badge-info";
      title = "Reviewed";
      break;
    case 3:
      className = "badge-danger";
      title = "Deleted";
      break;
    default:
      className = "badge-success";
      title = "Active";
      break;
  }

  return { title, className };
};

export const formatPaymentStatus = (status: number) => {
  let className;
  let title, icon;
  switch (status) {
    case 2:
      className = "badge-warning";
      title = "Unpaid";
      icon = "fa-clock-o";
      break;
    case 3:
      className = "badge-info";
      title = "Partially Paid";
      icon = "fa fa-hourglass-half";
      break;
    case 4:
      className = "badge-danger";
      title = "Overdue";
      icon = " fa fa-hourglass-1";
      break;
    default:
      className = "badge-success";
      title = "Paid";
      icon = "fa-check-circle";
      break;
  }

  return { title, className, icon };
};

export const formatParameterStatus = (status: number) => {
  let className;
  let title;
  switch (status) {
    case 2:
      className = "badge-info";
      title = "Active";
      break;
    case 3:
      className = "badge-success";
      title = "Completed";
      break;
    case 4:
      className = "badge-danger";
      title = "Suspended";
      break;
    default:
      className = "badge-info";
      title = "Pending";
      break;
  }

  return { title, className };
};

export const activityAction = (actionId: number) => {
  let className;
  switch (actionId) {
    case 2:
      className = "bg-info text-white";
      break;
    case 3:
      className = "bg-danger text-white";
      break;
    default:
      className = "badge-primary";
      break;
  }

  return className;
};

export const isImage = (url: string) => {
  if (!url) return false;
  if (url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
};

export const updateSession = (value: any): void => {
  const user = sessionStorage.getItem("user");
  if (user) {
    let prevData = JSON.parse(user);
    Object.keys(value).forEach(function (val, key) {
      prevData["user"][val] = value[val];
    });
    sessionStorage.setItem("user", JSON.stringify(prevData));
  }
};

export const getData = (dataSet: Array<any>, filter: any) => {
  if (dataSet.length === 0) return [];
  return dataSet[filter];
};

export const getFormData = (dataSet: any) => {
  let response: any = {};
  for (let x in dataSet) {
    response[x] = dataSet[x].data;
  }
  return response;
};

export const getItemData = (dataSet: any) => {
  let response: Array<any> = [];
  for (let x in dataSet) {
    response.push(dataSet[x].data);
  }
  return response;
};

export const formatQoutationSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: res?.description + " (" + res?.request_date + ")",
  }));

export const formatbidAnanlysisSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: res?.id + " - " + res?.supplier?.name,
  }));

export const formatbidSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: res?.id + " - " + res?.reference_no + " - " + res?.delivery_date,
  }));

export const formatPoSelectData = (data: any[], suppliers: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label:
      res?.id +
      " - " +
      res?.reference_no +
      " - " +
      suppliers.filter((item: any) => item.id === res?.supplier_id)[0]["name"],
  }));

export const formatRequisitionSelectData = (data: any[]) =>
  data.map((res) => ({
    value: res?.id,
    label: res?.purchase_requisition_no + ". " + res?.requisition_date,
  }));

export const formatToObject = (
  dataSet: Array<any>,
  stateData: iFormDefaultData,
  IsItems = false
) => {
  let subTotal = 0;
  let discount = Number(stateData.discount.data);
  // let totalTax = 0;
  if (IsItems) {
    dataSet.forEach((item: any) => {
      subTotal += Number(item?.total);
    });
  } else {
    dataSet.forEach((item: any) => {
      subTotal += Number(item?.sub_total);
    });
  }

  return {
    sub_total: subTotal - discount,
    discount: discount,
  };
};

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function convertArrayOfObjectsToCSV(
  array: Array<any>,
  data: Array<any>
) {
  let result: any;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array: Array<any>, data: Array<any>) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, data);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const formatCurrency = (currency: number | unknown) => {
  if (typeof currency === "number" && currency < 0) {
    return "(" + currency.toLocaleString() + ")";
  }
  return Number(currency).toLocaleString();
};
